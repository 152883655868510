
import { defineComponent, reactive, toRefs } from "vue";

export default defineComponent({
  props: {
    title: String,
    list: Array,
    width: String,
    tag: String,
    checked: { type: [Object, String], required: true },
  },
  setup(props, cxt) {
    const state = reactive({
      value: props.checked,
      other: "",
      changeCheck() {
        cxt.emit("update:checked", state.value);
      },
      inputOther() {
        cxt.emit("update:checked", state.other);
      },
    });

    return {
      ...toRefs(state),
    };
  },
  watch: {
    checked(newVal) {
      this.value = newVal;
    },
  },
});

<template>
  <main-content title="高侍选型">
    <div class="intro">
      <div class="i_title">智能制造"最后一公里"解决方案</div>
      <div class="i_name">CoSi高侍</div>
      <div class="i_info">无人值守机器人</div>
      <div class="i_img">
        <img src="@/assets/cosi.png" />
      </div>
    </div>
    <div class="tools">
      <div class="type">零件类型</div>
      <radio
        :list="toolList"
        title="零件材料"
        width="25%"
        tag="tool"
        v-model:checked="form.tool"
      />
      <radio
        :list="typeList"
        title="材料形状*"
        width="50%"
        tag="shape"
        v-model:checked="form.shape"
      />
      <item title="零件毛胚重量*">
        <div class="h_item" v-for="item in component_weight" :key="item">
          <span>{{ item }}</span>
          <span class="h_item_txt">产能占比</span>
          <input
            type="number"
            min="0"
            max="100"
            v-model="form.component_weight[item]"
            @focus="handleFocus('component_weight', item)"
            @blur="handleBlur('component_weight', item, 100)"
          />%
        </div>
      </item>
      <item title="零件尺寸(异形零件按近似方体计算)">
        <div class="n_item" v-for="item in component_size" :key="item">
          <span class="n_item_txt">{{ item.type }}</span>
          <input
            type="number"
            min="0"
            :max="item.maxValue"
            v-model="form.component_size[item.tag]"
            @focus="handleFocus('component_size', item.tag)"
            @blur="handleBlur('component_size', item.tag, item.maxValue)"
          />
        </div>
      </item>
      <radio
        title="零件批量"
        :list="cNumsList"
        tag="nums"
        v-model:checked="form.component_nums"
        width="50%"
      />
    </div>

    <div class="tools">
      <div class="type">制造相关</div>
      <checkbox
        title="机床类型(可多选)*"
        :list="filterMachineList"
        v-model:checked="form.machine_type"
        tag="machine_type"
        width="50%"
        v-if="filterMachineList.length"
      />

      <radio
        title="现装夹方式*"
        tag="action_method"
        :list="filterActionMethodList"
        v-model:checked="form.action_method"
        width="33%"
        v-if="filterActionMethodList.length"
      />
      <checkbox
        title="设备品牌(可多选)"
        :list="serviceList"
        tag="service_type"
        width="33%"
        v-model:checked="form.service_type"
      />

      <radio
        title="节拍"
        :list="beatList"
        tag="beat"
        v-model:checked="form.beat"
        width="50%"
      />
    </div>

    <div class="submit">
      <button class="info" @click="router.push({ path: '/' })">
        返回上一页
      </button>
      <button @click="submitAction">您的最佳机型</button>
    </div>
    <template v-if="Object.keys(tip).length">
      <div class="tip" v-for="(item, key) in tip" :key="key">{{ item }}</div>
    </template>
  </main-content>
</template>

<script lang="ts">
import Checkbox from "@/components/form/checkbox.vue";
import Radio from "@/components/form/radio.vue";
import Item from "@/components/item.vue";
import mainContent from "@/components/mainContent.vue";
import { defineComponent, reactive, toRefs } from "vue";
import {
  data as filterData,
  machine_type_filter,
  action_method_filter,
} from "@/views/choose/filterValue";
import { machineTypeProps, stateProps } from "@/model/optionsModel";
import {
  optionsList,
  formatFormValuebeforeSubmit,
  ifMainValueIsUndefindTip,
} from "./data";
import { useRouter } from "vue-router";
import { saveRepo } from "@/service/api";

const isObject = (obj: machineTypeProps) => {
  return Object.prototype.toString.call(obj) === "[object Object]";
};

export default defineComponent({
  name: "choose",
  components: { mainContent, Radio, Item, Checkbox },
  setup() {
    const allList = reactive(optionsList);
    const router = useRouter();

    const state: stateProps = reactive({
      form: {
        tool: "",
        shape: { type: "", tag: "" },
        component_weight: {
          "0.1-1.3kg": 0,
          "1.4-3.6kg": 0,
          "3.7-6.6kg": 0,
        },
        component_size: {
          length: 0,
          height: 0,
          width: 0,
        },
        component_nums: "",
        machine_type: [],
        action_method: { type: "", tag: [""] },
        service_type: [],
        beat: "",
      },
      targetType: [],
      actionTargetType: [],
      tip: {},
      changeMachineTypeChose(arr: machineTypeProps[], type: string[]) {
        state.targetType = type;
        // state.form.machine_type = [arr[0]];
      },
      changeActionMethods(arr: machineTypeProps, type: string[]) {
        state.actionTargetType = type;
        // state.form.action_method = arr;
      },
    });

    return {
      ...toRefs(allList),
      ...toRefs(state),
      router,
    };
  },
  methods: {
    handleFocus(obj: string, key: string) {
      if (~~this.form[obj][key] === 0) {
        this.form[obj][key] = "";
      }
    },
    handleBlur(obj: string, key: string, maxValue: string) {
      if (!this.form[obj][key]) {
        this.form[obj][key] = 0;
      } else if (maxValue && ~~this.form[obj][key] > ~~maxValue) {
        this.form[obj][key] = maxValue;
      }
    },
    async saveResult(obj: any) {
      saveRepo(obj);
    },
    async submitAction() {
      const { status, data } = this.beforeSubmitCheck();
      if (!status) return;
      let finishFilter: string[] = [];
      if (this.filterActionMethodList.length) {
        finishFilter = this.actionTargetType.filter(
          (v) => this.form.action_method.tag.indexOf(v) > -1
        );
      } else {
        finishFilter = this.actionTargetType;
      }
      const compare = Object.keys(this.form.component_weight).sort((a, b) => {
        return this.form.component_weight[b] - this.form.component_weight[a];
      });

      const serise = finishFilter[0].split("-")[1];
      const type = filterData.component_height[compare[0]];
      const result = "CoSi-" + type + serise;
      await this.saveResult({ ...data, result });
      this.router.push({ path: "/result/" + result });
    },
    beforeSubmitCheck() {
      let data = {};
      this.tip = {};

      for (let i in this.form) {
        if (isObject(this.form[i]) && !formatFormValuebeforeSubmit[i]) {
          data[i] = this.form[i].type ? this.form[i].type : "";
        } else if (formatFormValuebeforeSubmit[i]) {
          data[i] = formatFormValuebeforeSubmit[i](this.form[i]);
        } else {
          data[i] = this.form[i];
        }

        if (data[i] === "") {
          if (i == "action_method" && !this.filterActionMethodList.length) {
            continue;
          }
          if (ifMainValueIsUndefindTip[i]) {
            this.tip[i] = ifMainValueIsUndefindTip[i];
          }
        }
      }
      data = {
        ...JSON.parse(sessionStorage.getItem("form") || "{}"),
        ...data,
      };

      return { status: !Object.keys(this.tip).length, data };
    },
  },
  computed: {
    filterMachineList() {
      if (!this.form.shape.tag) return [];
      const shapeTag: string = this.form.shape.tag;
      const filterResult: string[] = filterData.shape[shapeTag];

      let filters: string[] = [];

      for (let i of filterResult) {
        if (machine_type_filter[i]) {
          filters = filters.concat(machine_type_filter[i]);
        }
      }
      let set = Array.from(new Set(filters));
      const result: machineTypeProps[] = set.map((item) => {
        return {
          ...this.machineList[item],
          tag: filterData.machine_type[this.machineList[item].tag],
        };
      });

      result.push({ type: "其他", tag: ["CoSi-U", "CoSi-T", "CoSi-M"] });
      this.changeMachineTypeChose(result, filterResult);

      return result;
    },
    filterActionMethodList() {
      if (!this.form.machine_type.length) return [];
      const result: machineTypeProps[] = [];
      const allType = this.form.machine_type.map((item) => item.tag);

      const hasChoseMachineType = Array.from(allType.flat());
      const sameType: string[] = this.targetType.filter(
        (v) => hasChoseMachineType.indexOf(v) > -1
      );

      for (let i of sameType) {
        const filterResult: string[] = action_method_filter[i];
        if (filterResult) {
          filterResult.map((item) => {
            result.push({
              ...this.actionMethodsList[item],
              tag: filterData.action_method[this.actionMethodsList[item].tag],
            });
          });
        }
      }
      this.changeActionMethods(result[0], sameType);
      return result;
    },
  },
});
</script>

<style lang="scss" scoped>
$baseColor: #00dd1b;
$bgColor: #484848;
.tools {
  margin-bottom: 5vh;
}
.intro {
  text-align: center;
  .i_title {
    font-size: 22px;
  }

  .i_name {
    color: $baseColor;
    font-size: 22px;
    margin: 10px;
  }

  .i_info {
    letter-spacing: 0.5em;
  }

  .i_img {
    img {
      width: 98%;
      max-width: 450px;
    }
  }
}
.type {
  font-size: 18px;
  font-weight: bold;
}

input {
  background: transparent;
}
.h_item {
  .h_item_txt {
    padding-right: 10px;
    padding-left: 15%;
  }
  input {
    width: 30%;
    max-width: 80px;
    margin-right: 5px;
  }
}

.n_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    width: 70%;
  }
}

.submit {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-left: -10px;
  margin-top: 10vh;
  margin-bottom: 5vh;
  background: #2a2a2a;
  padding: 10px;
  .img {
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      width: 25px;
      margin-right: 8px;
    }
  }

  /* .s_btn {
    background: #999;
  } */
}
</style>
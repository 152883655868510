import { machineTypeProps, checkProps } from "../../model/optionsModel";

export const formatFormValuebeforeSubmit = {
    machine_type(val: machineTypeProps[]) {
        return val.map(item => item.type).join(",")
    },
    service_type(val: string[]) {
        return val.join(",")
    },
    component_weight(val: any) {
        let len = Object.keys(val).length
        const result: string[] = []
        for (const k in val) {
            if (!val[k]) {
                len--
            }
            result.push(`${k}:${val[k]}`)
        }
        return len > 0 ? result.join(",") : ""
    },
    component_size(val: any) {
        return Object.keys(val).map(item => item + ':' + val[item]).join(",")
    }
}

export const ifMainValueIsUndefindTip: checkProps = {
    component_weight: "请填写产能占比比重最大值",
    machine_type: "机床类型不能为空",
    shape: "材料形状不能为空",
    action_method: "现装夹方式不能为空"
}

export const optionsList = {
    toolList: ["铝合金", "钛合金", "CoCr", "不锈钢", "碳钢", "铜", "塑料"],
    typeList: [
        { type: "方形", tag: "rect" },
        { type: "圆形（棒料）", tag: "circle" },
        { type: "圆形（盘料）", tag: "circle_plane" },
        { type: "异形", tag: "diff" },
    ],
    component_weight: ["0.1-1.3kg", "1.4-3.6kg", "3.7-6.6kg"],
    component_size: [
        { type: "长(mm)", tag: "length", maxValue: 290 },
        { type: "宽/直径(mm)", tag: "width", maxValue: 150 },
        { type: "高(mm)", tag: "height", maxValue: 150 },
    ],
    cNumsList: ["1-50", "50-500", "500-5000", "5000+"],
    machineList: {
        five_cnc: { type: "5轴CNC", tag: "five_cnc" },
        four_cnc: { type: "4轴CNC", tag: "four_cnc" },
        three_cnc: { type: "3轴CNC", tag: "three_cnc" },
        cnc_center: { type: "数控车削中心", tag: "cnc_center" },
        turn_center: { type: "车铣复合中心", tag: "turn_center" },
        slow: { type: "慢走丝", tag: "slow" },
        elec: { type: "电火花", tag: "elec" },
        longmen: { type: "龙门加工中心", tag: "longmen" },
        coordinates: { type: "三坐标", tag: "coordinates" },
    },
    actionMethodsList: {
        manual_vise: { type: "手动虎钳", tag: "manual_vise" },
        zero_system: { type: "零点系统", tag: "zero_system" },
        sucker: { type: "真空吸盘", tag: "sucker" },
        pneumatic_vise: { type: "气动虎钳", tag: "pneumatic_vise" },
        hydraulic_fixture: { type: "液压夹具", tag: "hydraulic_fixture" },
    },
    serviceList: [
        "HAAS", "Mazak", "GF+", "DMG MORI", "牧野", "三菱", "西铁城", "北京精雕", "蔡司", "海克斯康"
    ],
    beatList: [
        '<10分钟', '10~30分钟', '30~120分钟', '>120分钟'
    ]
}

<template>
  <div class="every_item">
    <div class="subtitle">{{ title }}</div>
    <div class="item_chose">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="item"
        :style="{ width }"
      >
        <input
          type="checkbox"
          :value="item"
          v-model="value"
          :id="tag + index"
          @change="changeCheck"
        />
        <label :for="tag + index"><span></span>{{ item.type || item }}</label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from "vue";

export default defineComponent({
  props: {
    title: String,
    list: Array,
    width: String,
    tag: String,
    checked: { type: [Object, String], required: true },
  },
  setup(props, cxt) {
    const state = reactive({
      value: props.checked,
      other: "",
      changeCheck() {
        cxt.emit("update:checked", state.value);
      },
      inputOther() {
        cxt.emit("update:checked", state.other);
      },
    });

    return {
      ...toRefs(state),
    };
  },
  watch: {
    checked(newVal) {
      this.value = newVal;
    },
  },
});
</script>

<style lang="scss" scoped>
$baseColor: #00dd1b;
.item_close {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.item {
  display: inline-block;
  line-height: 40px;
  position: relative;
}
input {
  width: 18px;
  height: 18px;
  opacity: 0;
}
label {
  position: relative;
  span {
    position: absolute;
    width: 18px;
    height: 18px;
    border: 1px solid #bbb;
    top: 0px;
    left: -25px;
    border-radius: 5px;
  }
}
input:checked + label {
  //   color: $baseColor;
  span {
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      top: 4px;
      left: 1px;
      width: 65%;
      height: 25%;
      border-right: 4px solid $baseColor;
      border-top: 4px solid $baseColor;
      transform: rotate(135deg);
    }
  }
}
</style>
const U = "CoSi-U"
const M = "CoSi-M"
const T = "CoSi-T"


const shape = {
    'rect': [U, M],  // 方形
    'circle': [T, M],  // 圆形（棒料）
    'circle_plane': [T, U],  // 圆形（盘料）
    'diff': [M, U]  // 异形
}

const component_height = {
    "0.1-1.3kg": 5,
    "1.4-3.6kg": 10,
    "3.7-6.6kg": 16,
}

const machine_type = {
    'five_cnc': [U, M],  // 5轴CNC
    'four_cnc': [U, M],  // 4轴CNC
    'three_cnc': [U, M],  // 3轴CNC
    'cnc_center': [T],  // 数控车削中心
    'turn_center': [T],  // 车铣复合中心
    'slow': [U, T],  // 慢走丝
    'elec': [U, T],  // 电火花
    'longmen': ['other'],  // 龙门加工中心
    'coordinates': [U]  // 三坐标
}

export const machine_type_filter = {
    'CoSi-U': ['five_cnc', 'four_cnc', 'three_cnc', 'slow', 'elec', 'coordinates'],
    'CoSi-M': ['five_cnc', 'four_cnc', 'three_cnc'],
    'CoSi-T': ['cnc_center', 'turn_center', 'slow', 'elec']
}

const action_method = {
    'manual_vise': [M, U], // 手动虎钳
    'zero_system': [M], // 零点系统
    'sucker': [U], // 真空吸盘
    'pneumatic_vise': [U], // 气动虎钳
    'hydraulic_fixture': [U] // 液压夹具
}

export const action_method_filter = {
    'CoSi-M': ['manual_vise', 'zero_system'],
    'CoSi-U': ['sucker', 'pneumatic_vise', 'hydraulic_fixture']
}

export const data = {
    shape,
    component_height,
    machine_type,
    action_method,
    machine_type_filter
}

/* export const result = {
    "CoSi-M": {
        video: 
    }
} */